<template>
  <div class="resourceUse">
    <div class="top">
      <div class="title">
        <div class="title-text">资源使用</div>
        <div class="title-description">
          截止到{{ $moment(today).format('YYYY-MM-DD') }}
          00:00:00的最新数据。当前页面仅支持查看1000条数据。
        </div>
      </div>

      <div class="search">
        <a-form :form="form">
          <a-form-item>
            <a-input
              placeholder="请输入租户名称"
              v-decorator="['keyWord']"
              allow-clear
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-select v-decorator="['isReal', { initialValue: defaultIsReal }]">
              <a-select-option value="">全部</a-select-option>
              <a-select-option value="1">跟进中</a-select-option>
              <a-select-option value="0">未跟进</a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
        <div class="search-button">
          <a-button type="primary" style="margin-right: 10px" @click="search"
            >查询</a-button
          >
          <a-button @click="reSet">重置</a-button>
        </div>
      </div>
    </div>
    <a-divider></a-divider>

    <div class="search-result">
      <div>查询结果：共 {{ searchResult }} 条</div>
      <div>
        排序：<a-select v-model="sortMethod" @select="sortChange"
          ><a-select-option value="0">日期从近到远</a-select-option
          ><a-select-option value="1">日期从远到近</a-select-option></a-select
        >
      </div>
    </div>

    <a-table
      :columns="columns"
      :data-source="resourceUseList"
      bordered
      :rowKey="(record) => record.userId"
      :pagination="pagination"
      @change="tablePageChange"
      :scroll="{ x: 1300 }"
    >
      <!-- 用户数量 -->
      <div slot="userCount" slot-scope="text, record">
        <span :style="{ color: record.userUsed >= text ? '#FF5D5C' : '' }"
          >{{ record.userUsed }}/{{ text }}</span
        >
      </div>
      <!-- 存储容量 -->
      <div slot="storageCount" slot-scope="text, record">
        <span :style="{ color: record.storageUsed >= text ? '#FF5D5C' : '' }"
          >{{ record.storageUsed }}/{{ text }}</span
        >
      </div>
      <!-- 存储流量 -->
      <div slot="flowCount" slot-scope="text, record">
        <span :style="{ color: record.flowUsed >= text ? '#FF5D5C' : '' }"
          >{{ record.flowUsed }}/{{ text }}</span
        >
      </div>
      <!-- 短信数量 -->
      <div slot="smsCount" slot-scope="text, record">
        <span :style="{ color: record.smsUsed >= text ? '#FF5D5C' : '' }"
          >{{ record.smsUsed }}/{{ text }}</span
        >
      </div>
      <!-- 表单应用数量 -->
      <div slot="tableAppCount" slot-scope="text, record">
        <span :style="{ color: record.flowUsed >= text ? '#FF5D5C' : '' }"
          >{{ record.tableAppUsed }}/{{ text }}</span
        >
      </div>
      <!-- 流程应用数量 -->
      <div slot="processAppCount" slot-scope="text, record">
        <span :style="{ color: record.processAppUsed >= text ? '#FF5D5C' : '' }"
          >{{ record.processAppUsed }}/{{ text }}</span
        >
      </div>
      <!-- 大屏应用数量 -->
      <div slot="bigScreenCount" slot-scope="text, record">
        <span :style="{ color: record.bigScreenUsed >= text ? '#FF5D5C' : '' }"
          >{{ record.bigScreenUsed }}/{{ text }}</span
        >
      </div>
      <!-- 移动门户装修应用数量 -->
      <div slot="phoneFixturesAppCount" slot-scope="text, record">
        <span
          :style="{
            color: record.phoneFixturesAppUsed >= text ? '#FF5D5C' : '',
          }"
          >{{ record.phoneFixturesAppUsed }}/{{ text }}</span
        >
      </div>
      <!-- 操作列 -->
      <div slot="operation" slot-scope="text, record">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            修改 <a-icon type="down" />
          </a>
          <a-menu slot="overlay" @click="menuItemClick($event, record)">
            <a-menu-item key="用户数量">
              <a>用户数量</a>
            </a-menu-item>
            <a-menu-item key="存储容量">
              <a>存储容量</a>
            </a-menu-item>
            <a-menu-item key="存储流量">
              <a>存储流量</a>
            </a-menu-item>
            <a-menu-item key="短信数量">
              <a>短信数量</a>
            </a-menu-item>
            <a-menu-item key="表单应用数量">
              <a>表单应用数量</a>
            </a-menu-item>
            <a-menu-item key="流程应用数量">
              <a>流程应用数量</a>
            </a-menu-item>
            <a-menu-item key="大屏应用数量">
              <a>大屏应用数量</a>
            </a-menu-item>
            <a-menu-item key="移动门户装修应用数量">
              <a>移动门户装修应用数量</a>
            </a-menu-item>
            <a-menu-item key="控制面板应用数量">
              <a>控制面板应用数量</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </a-table>
    <resource-modify
      v-if="modifyType"
      :visible="visible"
      :modifyData="modifyData"
      :modifyType="modifyType"
      @close="close"
      @refresh="refresh"
      :rowKey="(record) => record.TenantName"
    ></resource-modify>
  </div>
</template>

<script>
import resourceModify from '@/views/resourceManagement/modal/resourceModify.vue'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    scopedSlots: { customRender: 'index' },
    align: 'center',
    fixed: 'left',
    width: '50px',
  },
  {
    title: '租户名称',
    dataIndex: 'tname',
    key: 'tname',
    scopedSlots: { customRender: 'tname' },
    align: 'left',
    fixed: 'left',
    width: '250px',
  },
  {
    title: '用户数量（人）',
    dataIndex: 'userCount',
    key: 'userCount',
    scopedSlots: { customRender: 'userCount' },
    align: 'center',
  },
  {
    title: '存储容量（G）',
    dataIndex: 'storageCount',
    key: 'storageCount',
    scopedSlots: { customRender: 'storageCount' },
    align: 'center',
  },
  {
    title: '存储流量（G）',
    dataIndex: 'flowCount',
    key: 'flowCount',
    scopedSlots: { customRender: 'flowCount' },
    align: 'center',
  },
  {
    title: '短信数量（条）',
    dataIndex: 'smsCount',
    key: 'smsCount',
    scopedSlots: { customRender: 'smsCount' },
    align: 'center',
  },
  {
    title: '表单应用数量（个）',
    dataIndex: 'tableAppCount',
    key: 'tableAppCount',
    scopedSlots: { customRender: 'tableAppCount' },
    align: 'center',
  },
  {
    title: '流程应用数量（个）',
    dataIndex: 'processAppCount',
    key: 'processAppCount',
    scopedSlots: { customRender: 'processAppCount' },
    align: 'center',
  },
  {
    title: '大屏应用数量（个）',
    dataIndex: 'bigScreenCount',
    key: 'bigScreenCount',
    scopedSlots: { customRender: 'bigScreenCount' },
    align: 'center',
  },
  {
    title: '移动门户装修应用数量（个）',
    dataIndex: 'phoneFixturesAppCount',
    key: 'phoneFixturesAppCount',
    scopedSlots: { customRender: 'phoneFixturesAppCount' },
    align: 'center',
  },
  {
    title: '控制面板应用数量（个）',
    dataIndex: 'controlPanelAppCount',
    key: 'controlPanelAppCount',
    scopedSlots: { customRender: 'controlPanelAppCount' },
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'center',
    fixed: 'right',
    width: '140px',
  },
]

export default {
  name: 'ResourceUse',
  components: { resourceModify },
  data() {
    return {
      today: new Date(),
      form: this.$form.createForm(this),
      searchResult: 0,
      columns,
      resourceUseList: [],
      pagination: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '15', '20'],
      },
      visible: false /* 是否显示修改对话框 */,
      modifyData: {},
      modifyType: '',
      sortMethod: '1',
      defaultIsReal: '',
    }
  },
  async created() {
    // /* 获取筛选条件 */
    // this.defaultIsReal = await this.$store.state.condition
    // if (this.defaultIsReal == '') {
    //   this.getResourceUseList()
    // } else {
    //   this.getResourceUseList({ isReal: this.defaultIsReal })
    // }
    this.init()
  },

  beforeDestroy() {
    /* 页面刷新前保存，筛选条件 */
    this.saveCondition()
  },
  methods: {
    async init() {
      /* 获取筛选条件 */
      this.defaultIsReal = await this.$store.state.condition
      if (this.defaultIsReal == '') {
        this.getResourceUseList()
      } else {
        this.getResourceUseList({ isReal: this.defaultIsReal })
      }
    },
    /* 刷新 */
    refresh() {
      this.visible = false
      this.tablePageChange(this.pagination)
    },
    /* 页面刷新前保存，筛选条件 */
    saveCondition() {
      let condition = this.form.getFieldValue('isReal')
      // console.log(condition, '筛选条件')
      this.$store.commit('saveCondition', condition)
    },
    /* 获取资源使用情况列表 */
    getResourceUseList(params) {
      this.$api.getResourceUseByTenant(params).then((res) => {
        // console.log(res, '租户资源使用情况列表')
        if (res.code === 0) {
          this.resourceUseList = res.data.records
          this.pagination.total = Number(res.data.total)
          this.pagination.current = res.data.page
          this.searchResult = Number(res.data.total)
          this.resourceUseList.forEach((item, index) => {
            this.$set(item, 'index', index + 1)
          })
        }
      })
    },
    /* 分页设置改变 */
    tablePageChange(pagination) {
      const limit = pagination.pageSize
      const page = pagination.current
      let params = { limit, page }
      /* 条件查询框中的条件 */
      let searchValue = this.form.getFieldsValue()
      params = {
        ...params,
        ...searchValue,
        dateSort: this.sortMethod,
      }
      params = this.cleanEmpty(params)
      this.getResourceUseList(params)
    },
    /* 查找 */
    search() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(this.cleanEmpty(values), '表单数据')
          values = {
            ...values,
            dateSort: this.sortMethod,
          }
          values = this.cleanEmpty(values)
          this.getResourceUseList(values)
        }
      })
    },
    /* 重置条件查找表单 */
    reSet() {
      this.form.resetFields()
      this.search()
    },
    /* 下拉菜单项选中 */
    menuItemClick({ key }, record) {
      this.modifyType = key
      this.modifyData = record
      this.visible = true
    },
    /* 关闭弹窗 */
    close() {
      this.visible = false
      this.modifyType = ''
    },
    /* 去除对象中的空值、undefined、‘’ */
    cleanEmpty(value) {
      return Object.keys(value)
        .filter(
          (item) =>
            value[item] !== undefined &&
            value[item] !== '' &&
            value[item] !== null
        )
        .reduce((prev, curr) => ({ ...prev, [curr]: value[curr] }), {})
    },
    /* 列表排序改变 */
    sortChange(value) {
      // console.log(value)
      this.sortMethod = value
      this.search()
    },
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/CSS/global.less';

.resourceUse {
  background-color: #fff;
  height: calc(100vh - 120px);
  // margin-top: 5px;
  padding: 15px;
  border-radius: 3px;
  // overflow: auto;
}

.top {
  .title {
    // height: 100px;
    margin-bottom: 15px;
    .title-text {
      margin-left: 10px;
      margin-bottom: 15px;
      position: relative;
      font-family: '.PingFang SC S0pxibold';
      font-weight: 600;
      font-size: 16px;
      text-align: left;
      color: #151515;
      /* 伪类 */
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translateY(-50%);
        width: 4px;
        height: 20px;
        border-radius: 3px;
        background: #1890ff;
      }
    }
    .title-description {
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      color: #999;
    }
  }

  /deep/ .search {
    // width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    .search-button {
      position: absolute;
      right: 0;
      button {
        width: 100px;
      }
    }
    .ant-form {
      display: flex;
      align-items: center;
    }
    .ant-form-item {
      width: 280px;
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
}

/deep/ .search-result {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  color: #4e4e4e;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  div {
    margin-right: 30px;
    .ant-select-selection {
      border: none;
      width: 130px;
    }
  }
}

/deep/ .ant-table-body {
  overflow-y: hidden;
}
</style>
