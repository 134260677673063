<template>
  <a-modal
    :visible="visible"
    @ok="handleOk"
    @cancel="handleCancel"
    width="540px"
  >
    <div slot="title">
      <span>{{ modifyType }}</span>
    </div>
    <div>
      <div>{{ modifyType }}({{ unit }}):</div>
      <div class="count">{{ number }}</div>
    </div>
    <a-form :form="form">
      <a-form-item :label="'追加（' + unit + ')'">
        <a-input
          placeholder="请输入数字"
          v-decorator="[
            'resourceNum',
            { rules: [{ required: true, message: '请输入数字' }] },
          ]"
        ></a-input>
      </a-form-item>
      <a-form-item label="有效期至">
        <a-date-picker
          style="width: 100%"
          placeholder="请选择有效期"
          v-decorator="[
            'vlideTime',
            { rules: [{ required: true, message: '请选择有效期' }] },
          ]"
        ></a-date-picker>
      </a-form-item>
    </a-form>
    <div class="record">
      <span v-for="item in resourceUseHistory" :key="item.id"
        >{{ item.source }}: {{ item.resourceNum }}, 有效期至:
        {{ item.vlideTime }}</span
      >
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'resourceModify',
  inject: ['reload'],
  data() {
    return {
      form: this.$form.createForm(this),
      number: '',
      resourceType: 0 /* 资源类型：1用户，2短信，3存储，4流量，5表单应用，6流程应用，7大屏应用，8移动装修应用 */,
      resourceUseHistory: [],
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    modifyData: {
      type: Object,
      default: () => {},
    },
    modifyType: {
      type: String,
      default: '',
    },
  },
  created() {},
  watch: {
    resourceType: {
      handler(newVal, oldVal) {
        this.getHistoricalDataByTenant()
      },
      deep: true,
    },
  },
  methods: {
    /* 获取对应租户的对应类型资源历史数据 */
    getHistoricalDataByTenant() {
      this.$api
        .getHistoricalDataByTenant(this.modifyData.tid, this.resourceType)
        .then((res) => {
          // console.log(res, '资源历史记录')
          if (res.code === 0) {
            this.resourceUseHistory = res.data.records
          }
        })
    },
    /* 将对象转换成formData类型 */
    changeToFormData(object) {
      const formData = new FormData()
      Object.keys(object).forEach((key) => {
        formData.append(key, object[key])
      })
      return formData
    },
    /* 确认修改 */
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.vlideTime = this.$moment(values.vlideTime).format(
            'YYYY-MM-DD HH:mm:ss'
          )
          const data = {
            ...values,
            source: '追加',
            tid: this.modifyData.tid,
            resourceType: this.resourceType,
          }
          const data2 = this.changeToFormData(data)
          this.$api
            .resourceCountAddByType(data2)
            .then((res) => {
              // console.log(res, '追加')
              if (res.code === 0) {
                this.$message.success('追加成功')
                // this.reload()
                this.$emit('refresh')
              } else {
                this.$message.error('追加失败')
                // this.reload()
                this.$emit('refresh')
              }
            })
            .catch((err) => {
              this.$message.error('追加失败')
              console.log(err, '追加失败')
            })
        }
      })
    },

    /* 取消 */
    handleCancel() {
      this.$emit('close')
    },
  },
  computed: {
    unit() {
      switch (this.modifyType) {
        case '用户数量':
          this.number = this.modifyData.userCount
          this.resourceType = 1
          return '人'
        case '短信数量':
          this.number = this.modifyData.smsCount
          this.resourceType = 2
          return '条'
        case '存储容量':
          this.number = this.modifyData.storageCount
          this.resourceType = 3
          return 'G'
        case '存储流量':
          this.number = this.modifyData.flowCount
          this.resourceType = 4
          return 'G'
        case '表单应用数量':
          this.number = this.modifyData.tableAppCount
          this.resourceType = 5
          return '个'
        case '流程应用数量':
          this.number = this.modifyData.processAppCount
          this.resourceType = 6
          return '个'
        case '大屏应用数量':
          this.number = this.modifyData.bigScreenCount
          this.resourceType = 7
          return '个'
        case '移动门户装修应用数量':
          this.number = this.modifyData.phoneFixturesAppCount
          this.resourceType = 8
          return '个'
        case '控制面板应用数量':
          this.number = this.modifyData.phoneFixturesAppCount
          this.resourceType = 9
          return '个'
      }
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 10px;
}

/deep/ .ant-modal-body {
  padding-bottom: 50px;
}

.record {
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: flex-start;
  span {
    font-family: 'PingFangSC-Regular ';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: #666;
    margin-bottom: 10px;
  }
}

.count {
  font-size: 30px;
  color: #1a1a1a;
}
</style>
